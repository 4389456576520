<template>
  <div>
    <el-row
      v-for="(item, index) in items"
      :key="index"
      type="flex"
      class="items__row"
      justify="space-between"
      align="middle">
      <div class="items__info">
        <div>
          <span class="items__date">{{ itemDate(item) }}</span>
          <span class="items__typology" v-if="itemTypology(item)">{{ itemTypology(item) }}</span>
          <span class="items__title">{{ item.title.rendered }}</span>
        </div>
        <div class="items__excerpt" v-html="item.excerpt.rendered"></div>
      </div>
      <router-link
        class="button button--primary items__button"
        :to="{ name: entity, params: { id: item.id, item: item } }">
        {{ $t('global.open') }}
      </router-link>
    </el-row>
  </div>
</template>

<script>
import i18n from '@/i18n';

export default {
  name: 'CommunicationRow',
  props: [
    'items',
    'entity',
  ],
  methods: {
    itemTypology(item) {
      if (item.type === 'market-trend') {
        return i18n.t(`marketTrends.${item.acf.typology}`);
      }
      return item.acf.typology;
    },
    itemDate(item) {
      if (item.type === 'market-trend'
        && (item.acf.typology === 'weekly' || item.acf.typology === 'outlook')
      ) {
        return `${i18n.t('global.from')} 
          ${item.acf.date_from} 
          ${i18n.t('global.to')} 
          ${item.acf.date_to}`;
      }
      return item.acf.date;
    },
  },
};
</script>
